import { cx } from "@linaria/core";
import * as React from "react";
import { FragmentType, graphql, useFragment } from "@/gql";
import Link from "next/link";
import Image from "next/image";
import imageLoader from "src/imageLoader";

/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = Link;
interface Props extends Omit<React.ComponentPropsWithoutRef<typeof Root>, "href"> {
  blob: FragmentType<typeof BrickFragment>;
}
function Brick(props: Props, ref: React.ForwardedRef<React.ElementRef<typeof Root>>) {
  const {
    className,
    blob: blob0,
    ...rest
  } = props;
  const blob = useFragment(BrickFragment, blob0);
  return <Root ref={ref} href={`/b/${blob.name}`} prefetch={false} className={cx(className, classes.root)} {...rest}>
      {(() => {
      if (blob.asImage) {
        return <Image className={classes.image} alt="" sizes="(min-width: 1376px) 25vw, (min-width: 1060px) 30vw, (min-width: 744px) 50vw, 100vw" src={blob.asImage.url} loader={imageLoader} {...blob.asImage?.dimensions} />;
      }
      if (blob.asVideo) {
        return <Image className={classes.image} alt="" sizes="(min-width: 1376px) 25vw, (min-width: 1060px) 30vw, (min-width: 744px) 50vw, 100vw" src={blob.asVideo.poster.url} loader={imageLoader} {...blob.asVideo.poster.dimensions} />;
      }
      return null;
    })()}
    </Root>;
}
const classes = {
  root: "ruqqp8n",
  image: "i1zp37g"
};
export default React.forwardRef(Brick);
const BrickFragment = graphql( /* GraphQL */`
  fragment Brick on Blob {
    id

    name

    asImage {
      id

      url
      dimensions {
        width
        height
      }
    }

    asVideo {
      id

      poster {
        id

        url
        dimensions {
          width
          height
        }
      }
    }
  }
`);

require("./Brick.linaria.module.css");