"use client";

import { Masonry } from "@/components/Masonry";
import * as React from "react";
import { useImmer } from "use-immer";
import { Provider, createClient, fetchExchange, useQuery } from "urql";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import { graphql } from "@/gql";
import { HomePageQueryVariables } from "@/gql/graphql";
import { Brick } from "@/components/Brick";
const initialVariables: HomePageQueryVariables = {
  first: 30,
  after: null
};
const HomePageQuery = graphql( /* GraphQL */`
  query HomePage($first: Int!, $after: String) {
    blobs(first: $first, after: $after, orderBy: { field: CREATED_AT, direction: DESC }) {
      __typename # cachekey
      pageInfo {
        __typename # cachekey
        endCursor
        hasNextPage
      }

      edges {
        __typename # cachekey
        cursor
        node {
          id
          ...Brick
        }
      }
    }
  }
`);
function Page() {
  const [state, mutate] = useImmer({
    variables: initialVariables
  });
  const [result] = useQuery({
    query: HomePageQuery,
    variables: state.variables
  });
  const containerRef = React.useRef<null | HTMLDivElement>(null);
  const blobs = result.data?.blobs;
  const loadMore = React.useCallback(() => {
    if (!blobs || !blobs.pageInfo) {
      return;
    }
    if (!blobs.pageInfo.hasNextPage) {
      return;
    }
    if (!containerRef.current) {
      return;
    }
    if (containerRef.current.getBoundingClientRect().bottom >= window.innerHeight + 600) {
      return;
    }
    requestAnimationFrame(() => {
      mutate(draft => {
        draft.variables.after = blobs.edges[blobs.edges.length - 1].cursor;
      });
    });
  }, [blobs, mutate]);
  React.useEffect(() => {
    loadMore();
    window.addEventListener("scroll", loadMore, {
      passive: true
    });
    window.addEventListener("resize", loadMore, {
      passive: true
    });
    return () => {
      window.removeEventListener("scroll", loadMore);
      window.removeEventListener("resize", loadMore);
    };
  }, [loadMore]);
  return <div>
      <div ref={containerRef} className={"d1bdmy7h"}>
        <Masonry id="home">{result.data?.blobs?.edges?.map(({
          node
        }) => <Brick key={node.id} blob={node} />)}</Masonry>
      </div>
    </div>;
}
const client = createClient({
  url: `${process.env.API}/graphql`,
  fetchOptions: {
    credentials: "include"
  },
  exchanges: [devtoolsExchange, cacheExchange({
    keys: {
      Dimensions: () => null
    },
    resolvers: {
      Query: {
        blobs: relayPagination()
      }
    }
  }), fetchExchange]
});
export default (() => <Provider value={client}>
    <Page />
  </Provider>);

require("./page.linaria.module.css");